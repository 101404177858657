<template>
  <div class="page-wrapper">
    <Header />
    <Banner />
    <Services />
    <About />
    <Product />
    <BestSeller />
    <Footer />
    <ScrollTop />
  </div>
  
</template>

<script>
import Header from '@/components/Header.vue'
import Banner from '@/components/Banner.vue'
import Services from '@/components/Services.vue'
import About from '@/components/About.vue'
import Product from '@/components/Product.vue'
import BestSeller from '@/components/BestSeller.vue'
import Footer from '@/components/Footer.vue'
import ScrollTop from '@/components/ScrollTop.vue'

export default {
  name: 'PageHome',
  components: {
    Header,
    Banner,
    Services,
    About,
    Product,
    BestSeller,
    Footer,
    ScrollTop,
  },
}
</script>
