<template>
  <div>
    <!-- Offer Section -->
    <section>
      <div class="row m-5">
        <div class="col-lg-12 col-md-12 col-sm-12">
          <p>WeFlorist is a florists that has been established since 2021 by sending the best quality flower arrangements, same-day delivery to Jakarta, Surabaya, Bandung, Semarang, Medan, Bali, &amp; 50+ other major cities in Indonesia.</p><br><p>There are many ways to express feelings at any given moment. One way is to give flowers as a messenger. We will help you get your message across in the moment by providing high quality flowers.</p><br><p>Our location is located in Rawa Belong, West Jakarta. serve the various needs of quality flower arrangements at the best prices. We also make it easy to order flowers, because our system supports online. You can visit the website to see our flower collection. If you experience difficulties, you can contact our customer service who will be happy to serve via the Whatsapp application.</p><br><p>The most valuable thing is your trust, so we will provide the best quality flowers. We also accept requests for flower arrangements as you wish with the best price offers.</p>
        </div>
      </div>
    </section>
    <!--End Offer Section -->
  </div>
</template>

<script>
export default {
  name: 'ComponentsAboutBody',
}
</script>
