<template>
  <div class="page-wrapper">
    <Header />
    <Banner />
    <Product />
    <Footer />
  </div>
  <ScrollTop />
</template>

<script>
import Header from '@/components/Header.vue'
import Banner from '@/components/product/Banner.vue'
import Product from '@/components/product/Product.vue'
import Footer from '@/components/Footer.vue'
import ScrollTop from '@/components/ScrollTop.vue'

export default {
  name: 'PageProduct',
  components: {
    Header,
    Banner,
    Footer,
    Product,
    ScrollTop,
  },
}
</script>
