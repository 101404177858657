<template>
  <div>
    <section class="fun-fact-and-features p-5">
      <div class="outer-box">
        <div class="auto-container">
          <!-- Features -->
          <div class="features">
            <div class="row">
                <!-- Feature Block -->
                <div class="feature-block col-lg-4 col-md-6 col-sm-12">
                    <div class="inner-box">
                        <div class="icon-box">
                          <center>
                            <span class="icon">
                              <img src="@/assets/images/services/choices.png" alt="">
                            </span>
                          </center>
                        </div>
                        <h3><a href="service-detail.html">Made by Order</a></h3>
                        <div class="text text-center">By providing these detailed instructions, the seller can ensure that your order is customized to your liking</div>
                    </div>
                </div>

                <!-- Feature Block -->
                <div class="feature-block col-lg-4 col-md-6 col-sm-12">
                    <div class="inner-box">
                        <div class="icon-box">
                          <center>
                            <span class="icon">
                              <img src="@/assets/images/services/trust.png" alt="">
                            </span>
                          </center>
                        </div>
                        <h3><a href="service-detail.html">Trusted</a></h3>
                        <div class="text text-center">Our guarantee is to provide flower arrangements that give happiness and meet your expectations</div>
                    </div>
                </div>

                <!-- Feature Block -->
                <div class="feature-block col-lg-4 col-md-6 col-sm-12">
                    <div class="inner-box">
                        <div class="icon-box">
                          <center>
                            <span class="icon">
                              <img src="@/assets/images/services/funding.png" alt="">
                            </span>
                          </center>
                        </div>
                        <h3><a href="service-detail.html">Affordable Prices</a></h3>
                        <div class="text text-center">every customer has a unique taste and budget, so we strive to provide affordable options without compromising on quality.</div>
                    </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'ComponentsServices',
}
</script>