<template>
  <div>
    <footer class="main-footer mt-5 mr-5 ml-5">
      <div class="auto-container">
          <!--Widgets Section-->
          <div class="widgets-section">
              <div class="row">
                  <!--Big Column-->
                  <div class="big-column col-xl-7 col-lg-12 col-md-12 col-sm-12">
                      <div class="row">
                          <!--Footer Column-->
                          <div class="footer-column col-xl-12 col-lg-12 col-md-12 col-sm-12 p-3">
                              <div class="footer-widget about-widget">
                                    <div class="footer-logo">
                                        <figure>
                                            <a href="#"><img src="@/assets/logo.png" alt=""></a>
                                        </figure>
                                    </div>
                                    <div class="contact-info p-3">
                                        <div class="row">
                                            <div class="info-block col-lg-4 col-md-4 col-sm-12">
                                                <div class="inner">
                                                    <h4 class="text-white">Location</h4>
                                                    <p>Jl. Madrasah II rt.007/011 No.48, Sukabumi Utara, Kec. Kb. Jeruk, Daerah Khusus Ibukota Jakarta 11540</p>
                                                </div>
                                            </div>

                                            <div class="info-block col-lg-4 col-md-4 col-sm-12">
                                                <div class="inner">
                                                    <h4 class="text-white">Call Us</h4>
                                                    <p>+62 812-1966-7791</p>
                                                </div>

                                            </div>

                                            <div class="info-block col-lg-4 col-md-4 col-sm-12">
                                                <div class="inner">
                                                    <h4 class="text-white">Email</h4>
                                                    <p>we.floristt@gmail.com</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="widget-content p-3">
                                        <div class="text">© 2021, FLORISTS. MADE WITH PASSION BY WE FLORIST</div>
                                    </div>
                              </div>
                          </div>     
                      </div>
                  </div>
                  
                  <!--Big Column-->
                  <div class="big-column col-xl-5 col-lg-12 col-md-12 col-sm-12">
                      <div class="row clearfix">
                            <!--Footer Column-->
                            <div class="form-column col-lg-12 col-md-12 col-sm-12">
                                <div class="inner-column">

                                    <div class="contact-form pr-5">
                                        <form method="post" action="#" id="contact-form">
                                            <div class="row">
                                                <div class="form-group col-lg-6 col-md-12 col-sm-12">
                                                    <input type="text" name="username" placeholder="Name" required="">
                                                </div>
                                                
                                                <div class="form-group col-lg-6 col-md-12 col-sm-12">
                                                    <input type="text" name="phone" placeholder="Phone" required="">
                                                </div>

                                                <div class="form-group col-lg-6 col-md-12 col-sm-12">
                                                    <input type="text" name="company" placeholder="Company">
                                                </div>

                                                <div class="form-group col-lg-6 col-md-12 col-sm-12">
                                                    <input type="email" name="email" placeholder="Email" required="">
                                                </div>

                                                <div class="form-group col-lg-12 col-md-12 col-sm-12">
                                                    <textarea name="message" placeholder="Massage"></textarea>
                                                </div>

                                                <div class="form-group col-lg-12 col-md-12 col-sm-12">
                                                    <button class="theme-btn btn-style-three" type="submit" name="submit-form">Submit</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
    </footer>
  </div>
</template>

<style>
  .main-footer .widgets-section {
    padding: 50px 0 0px !important;
  }
  .main-footer {
    background-image: url('@/assets/images/background/5.jpg');
  }
</style>

<script>
export default {
  name: 'ComponentsFooter',
}
</script>
