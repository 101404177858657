<template>
  <div>
    <!--Page Title-->
    <section class="page-title">
        <div class="auto-container">
            <div class="inner-container clearfix">
                <div class="title-box">
                    <h1 class="text-dark">About</h1>
                    <span class="title text-dark">Build Credibility and Build Trust with Customers</span>
                </div>
                <ul class="bread-crumb clearfix">
                    <li><router-link :to="{ name: 'Home' }">Home</router-link></li>
                    <li>About</li>
                </ul>
            </div>
        </div>
    </section>
    <!--End Page Title-->
  </div>
</template>

<style>
.page-title:before {
  background: url('../../assets/images/header/02.png') !important;
}
</style>

<script>
export default {
  name: 'ComponentsBannerProduct',
}
</script>