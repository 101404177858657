<template>
  <div>
    <!-- Bnner Section -->
    <section class="banner-section-three">
      <div class="banner-carousel-two owl-theme">
        <Carousel ref="carousel" :autoplay="5000" :wrap-around="true" v-model="currentSlide">
          <Slide v-for="slide in slider" :key="slide">
            <div class="carousel__item">
              <img :src="slide">
            </div>
          </Slide>

          <template #addons>
            <div class="bottom-box">
              <div class="auto-container">
                  <div class="outer-box clearfix">
                    <ul class="social-links clearfix">
                      <li><a target="_blank" href="https://wa.me/6281219667791?text=Hello%20We%20Florist!+Saya+ingin+melakukan+pesanan%2C+Mohon+informasinya.+Terimakasih"><span class="fa fa-whatsapp"></span> Whatsapp</a></li>
                      <li><a target="_blank" href="https://www.facebook.com/wefloristjakarta"><span class="fa fa-facebook-f"></span> Facebook</a></li>
                      <li><a target="_blank" href="https://www.instagram.com/weflorist_jakarta/"><span class="fa fa-instagram"></span> Instagram</a></li>
                      <li><a target="_blank" href="mailto:we.floristt@gmail.com?subject=Pemesanan&body=Hello%20We%20Florist!%20Saya%20ingin%20melakukan%20pesanan%2C%20Mohon%20informasinya.%20Terimakasih"><span class="fa fa-envelope"></span> Gmail</a></li>
                    </ul>
                  </div>
              </div>
            </div>
          </template>

        </Carousel>
      </div>
    </section>
    <!-- End Bnner Section -->
  </div>
</template>

<script>
import { Carousel, Slide } from 'vue3-carousel'
import Img1 from '@/assets/images/main-slider/01.png'
import Img2 from '@/assets/images/main-slider/02.png'
import Img3 from '@/assets/images/main-slider/03.png'
import Img4 from '@/assets/images/main-slider/04.png'

import 'vue3-carousel/dist/carousel.css'

export default {
  name: 'ComponentsBanner',
  components: {
    Carousel,
    Slide,
  },
  data () {
    return {
      currentSlide: 0,
      slider: [
        Img1,
        Img2,
        Img3,
        Img4
      ]
    }
  },
}
</script>
