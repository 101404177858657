<template>
  <div>
    <!--Orchid Section-->
    <section class="testimonial-section">
      <div class="outer-container clearfix">
          <!-- Testimonial Column -->
          <div class="testimonial-column clearfix">
              <div class="inner-column">
                  <div class="testimonial-carousel owl-theme">
                    <div class="col-sm-12">
                      <Carousel :itemsToShow="3.4" :wrapAround="true" :transition="500">
                        <Slide v-for="slide in slider" :key="slide">
                          <div class="carousel__item">
                            <img :src="slide" @click="modalshow(slide)">
                          </div>
                        </Slide>
                        <template #addons>
                          <Navigation />
                        </template>
                      </Carousel>
                    </div>
                  </div>
              </div>
          </div>
  
          <!-- Title Column -->
          <div class="title-column clearfix">
              <div class="inner-column">
                  <div class="sec-title">
                      <span class="float-text">Product</span>
                      <h2>Steekwerk</h2>
                  </div>
                  <div class="text">Is a common way to give appreciation, support, or express feelings for an important event. can be a beautiful and impressive decoration as well as an expression of emotion and hope from the sender.</div>
              </div>
          </div>
      </div>
    </section>
      <!--End Orchid Section-->
    <div class="modal" tabindex="-1" role="dialog" :class="showModal">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="modalclose2('d-none')">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <figure class="figure">
              <img class="figure-img img-fluid img-fluid mx-auto d-block" :src="imgModal">
            </figure>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Carousel, Slide, Navigation } from 'vue3-carousel'
import AImg1 from '@/assets/images/product/papan/01.jpg'
import AImg2 from '@/assets/images/product/papan/02.jpg'
import AImg3 from '@/assets/images/product/papan/03.jpg'
import AImg4 from '@/assets/images/product/papan/04.jpg'
import AImg5 from '@/assets/images/product/papan/05.jpg'
import AImg6 from '@/assets/images/product/papan/06.jpg'
import AImg7 from '@/assets/images/product/papan/07.jpg'
import AImg8 from '@/assets/images/product/papan/08.jpg'
import AImg9 from '@/assets/images/product/papan/09.jpg'
import AImg10 from '@/assets/images/product/papan/10.jpg'
import AImg11 from '@/assets/images/product/papan/11.jpg'
import AImg12 from '@/assets/images/product/papan/12.jpg'
import AImg13 from '@/assets/images/product/papan/13.jpg'
import AImg14 from '@/assets/images/product/papan/14.jpg'
import AImg15 from '@/assets/images/product/papan/15.jpg'
import AImg16 from '@/assets/images/product/papan/16.jpg'
import AImg17 from '@/assets/images/product/papan/17.jpg'
import 'vue3-carousel/dist/carousel.css'

export default {
  name: 'ComponentsOrchid',
  components: {
    Carousel,
    Slide,
    Navigation,
  },
  data () {
    return {
      showModal: 'd-none',
      imgModal: '',
      slider: [
        AImg1, 
        AImg2, 
        AImg3, 
        AImg4, 
        AImg5, 
        AImg6, 
        AImg7, 
        AImg8, 
        AImg9, 
        AImg10,
        AImg11,
        AImg12,
        AImg13,
        AImg14,
        AImg15,
        AImg16,
        AImg17,
      ]
    }
  },
  methods: {
    modalshow(val) {
      this.imgModal = val
      this.showModal = 'd-block'
    },
    modalclose2(val) {
      this.showModal = val
    },
  },
}
</script>