<template>
  <!--Orchid Section-->
  <section class="testimonial-section">
      <div class="outer-container clearfix">
          <!-- Title Column -->
          <div class="title-column clearfix">
              <div class="inner-column">
                  <div class="sec-title">
                      <span class="float-text">Product</span>
                      <h2>Standing Flower</h2>
                  </div>
                  <div class="text">Overall, used to create a striking visual impact and add a touch of elegance and sophistication to any event or space.</div>
              </div>
          </div>

          <!-- Testimonial Column -->
          <div class="testimonial-column clearfix mt-5">
              <div class="inner-column">
                  <div class="testimonial-carousel owl-theme">
                    <div class="col-sm-12">
                      <Carousel :itemsToShow="3.4" :wrapAround="true" :transition="500">
                        <Slide v-for="slide in slider" :key="slide">
                          <div class="carousel__item">
                            <img :src="slide" @click="modalshow(slide)">
                          </div>
                        </Slide>
                        <template #addons>
                          <Navigation />
                        </template>
                      </Carousel>
                    </div>
                  </div>
              </div>
          </div>
          <div class="modal" tabindex="-1" role="dialog" :class="showModal">
            <div class="modal-dialog modal-lg" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="modalclose('d-none')">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <figure class="figure">
                    <img class="figure-img img-fluid img-fluid mx-auto d-block" :src="imgModal">
                  </figure>
                </div>
              </div>
            </div>
          </div>
      </div>
    </section>
    <!--End Orchid Section-->
</template>

<script>
import { Carousel, Slide, Navigation } from 'vue3-carousel'
import AImg1 from '@/assets/images/product/standing/01.jpg'
import AImg2 from '@/assets/images/product/standing/02.jpg'
import AImg3 from '@/assets/images/product/standing/03.jpg'
import AImg4 from '@/assets/images/product/standing/04.jpg'
import AImg5 from '@/assets/images/product/standing/05.jpg'
import AImg6 from '@/assets/images/product/standing/06.jpg'
import AImg7 from '@/assets/images/product/standing/07.jpg'
import AImg8 from '@/assets/images/product/standing/08.jpg'
import AImg9 from '@/assets/images/product/standing/09.jpg'
import 'vue3-carousel/dist/carousel.css'

export default {
  name: 'ComponentsOrchid',
  components: {
    Carousel,
    Slide,
    Navigation,
  },
  data () {
    return {
      showModal: 'd-none',
      imgModal: '',
      slider: [
        AImg1, 
        AImg2, 
        AImg3, 
        AImg4, 
        AImg5, 
        AImg6, 
        AImg7, 
        AImg8, 
        AImg9,
      ]
    }
  },
  methods: {
    modalshow(val) {
      this.imgModal = val
      this.showModal = 'd-block'
    },
    modalclose(val) {
      this.showModal = val
    },
  },
}
</script>