<template>
  <div>
    <!-- Offer Section -->
    <section>
      <div class="row m-5">
        <div class="col-lg-12 col-md-12 col-sm-12">
          <div class="container-fluid">
            <div class="map-responsive">
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3966.413051761965!2d106.77815192499033!3d-6.209125243778723!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69f7054860e139%3A0xc9b91b1174bcf86!2sWe%20Florist!5e0!3m2!1sen!2sid!4v1697466700836!5m2!1sen!2sid" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!--End Offer Section -->
  </div>
</template>

<style>
.map-responsive{
    overflow:hidden;
    padding-bottom:50%;
    position:relative;
    height:0;
}
.map-responsive iframe{
    left:0;
    top:0;
    height:100%;
    width:100%;
    position:absolute;
}
</style>

<script>
export default {
  name: 'ComponentsAboutBody',
}
</script>
