<template>
    <div>
        <!-- Main Header-->
        <header class="main-header header-style-two">
            <!--Header Top-->
            <div class="header-top">
                <div class="auto-container">
                    <div class="inner-container clearfix">
                        <div class="top-left">
                            <ul class="contact-list clearfix">
                                <li><i class="fa fa-map-marker"></i> Rawa Belong, Jakarta. Indonesia</li>
                                <li><i class="fa fa-envelope"></i>we.floristt@gmail.com</li>
                                <li><i class="fa fa-volume-control-phone"></i> +62&nbsp;812-1966-7791</li>
                            </ul>
                        </div>
                        <div class="top-right">
                            <ul class="social-icon-four clearfix">
                                <li><a target="_blank" href="https://wa.me/6281219667791?text=Hello%20We%20Florist!+Saya+ingin+melakukan+pesanan%2C+Mohon+informasinya.+Terimakasih"><i class="fa fa-whatsapp"></i></a></li>
                                <li><a target="_blank" href="https://www.facebook.com/wefloristjakarta"><i class="fa fa-facebook-f"></i></a></li>
                                <li><a target="_blank" href="https://www.instagram.com/weflorist_jakarta/"><i class="fa fa-instagram"></i></a></li>
                                <li><a target="_blank" href="mailto:we.floristt@gmail.com?subject=Pemesanan&body=Hello%20We%20Florist!%20Saya%20ingin%20melakukan%20pesanan%2C%20Mohon%20informasinya.%20Terimakasih"><i class="fa fa-envelope"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <!-- End Header Top -->

            <div class="header-lower">
                <div class="auto-container">
                    <div class="main-box clearfix">
                        <div class="logo-box">
                            <div class="logo"><a href=""><img src="@/assets/logo.png" alt="" title=""></a></div>
                        </div>

                        <div class="nav-outer clearfix">
                            <!-- Main Menu -->
                            <nav class="main-menu navbar-expand-md ">
                                <div class="navbar-header">
                                    <!-- Toggle Button -->      
                                    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                        <span class="icon flaticon-menu-button"></span>
                                    </button>
                                </div>
                                
                                <div class="collapse navbar-collapse clearfix" id="navbarSupportedContent">
                                    <ul class="navigation clearfix">
                                        <li><router-link :to="{ name: 'Home' }">Home</router-link></li>
                                        <li><router-link :to="{ name: 'Product' }">Product</router-link></li>
                                        <li><router-link :to="{ name: 'About' }">About</router-link></li>
                                        <li><router-link :to="{ name: 'Contact' }">Contact</router-link></li>
                                    </ul>
                                </div>
                            </nav>
                            <!-- Main Menu End-->
                        </div>
                    </div>
                </div>
            </div>

            <!-- Sticky Header  -->
            <div class="sticky-header">
                <div class="auto-container clearfix">
                    <!--Logo-->
                    <div class="logo pull-left">
                        <a href="index-2.html" title=""><img src="@/assets/logo.png" alt="" title=""></a>
                    </div>
                    <!--Right Col-->
                    <div class="pull-right">
                        <!-- Main Menu -->
                        <nav class="main-menu">
                            <div class="navbar-collapse show collapse clearfix">
                                <ul class="navigation clearfix">
                                    <li><a href="">Home</a></li>
                                    <li><a href="">Product</a></li>
                                    <li><a href="">About</a></li>
                                    <li><a href="">Contact</a></li>
                                </ul> 
                            </div>
                        </nav><!-- Main Menu End-->
                    </div>
                </div>
            </div><!-- End Sticky Menu -->
        </header>
        <!--End Main Header -->
    </div>
</template>

<script>
export default {
  name: 'ComponentsHeader',
}
</script>
