<template>
  <div>
    <Orchid />
    <Steekwerk />
    <Standing />
  </div>
</template>

<style>
.carousel__prev,
.carousel__next {
	background-color: #fff7;
}
.modal-content img {
    width:50%;
}
</style>

<script>
import Orchid from './Orchid.vue'
import Steekwerk from './Steekwerk.vue'
import Standing from './Standing.vue'

export default {
  name: 'ComponentsProductAll',
  components: {
    Orchid,
    Steekwerk,
    Standing,
  },
}
</script>
