<template>
  <div>
    <!-- About Section -->
    <section class="about-section pt-0">
        <div class="auto-container">
            <div class="row no-gutters">
                <!-- Image Column -->
                <div class="image-column col-lg-6 col-md-12 col-sm-12">
                    <div class="inner-column">
                      <div class="image-box">
                        <div class="title-box wow fadeInLeft text-center" data-wow-delay='1200ms'>
                            <h2>ABOUT US</h2>
                        </div>
                        <div class="image-box">
                            <figure class="alphabet-img wow fadeInRight"><img src="@/assets/images/about/01.png" alt=""></figure>
                        </div>
                      </div>
                    </div>
                </div>

                <!-- Content Column -->
                <div class="content-column col-lg-6 col-md-12 col-sm-12">
                    <div class="inner-column wow fadeInLeft">
                        <div class="content-box">
                            <div class="title">
                                <h2>Quality at the Best Price</h2>
                            </div>
                            <div class="text">WeFlorist is a florists that has been established since 2021 by sending the best quality flower arrangements, same-day delivery to Jakarta, Surabaya, Bandung, Semarang, Medan, Bali, &amp; 50+ other major cities in Indonesia.</div>
                            <div class="link-box">
                              <router-link :to="{ name: 'About' }" class="theme-btn btn-style-one">About</router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!--End About Section -->
  </div>
</template>

<script>
export default {
  name: 'ComponentsAbout',
}
</script>
