<template>
  <!--Orchid Section-->
  <section class="testimonial-section">
      <div class="outer-container clearfix">
          <!-- Title Column -->
          <div class="title-column clearfix">
              <div class="inner-column">
                  <div class="sec-title">
                      <span class="float-text">Product</span>
                      <h2>Orchid</h2>
                  </div>
                  <div class="text">Known for its enduring allure, the Moon Orchid symbolizes love, purity, and sophistication. It is often given as a gift for special occasions to convey admiration and appreciation.</div>
              </div>
          </div>

          <!-- Testimonial Column -->
          <div class="testimonial-column clearfix mt-5">
              <div class="inner-column">
                  <div class="testimonial-carousel owl-theme">
                    <div class="col-sm-12">
                      <Carousel :itemsToShow="3.4" :wrapAround="true" :transition="500">
                        <Slide v-for="slide in slider" :key="slide">
                          <div class="carousel__item">
                            <img :src="slide" @click="modalshow(slide)">
                          </div>
                        </Slide>
                        <template #addons>
                          <Navigation />
                        </template>
                      </Carousel>
                    </div>
                  </div>
              </div>
          </div>
          <div class="modal" tabindex="-1" role="dialog" :class="showModal">
            <div class="modal-dialog modal-lg" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="modalclose('d-none')">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <figure class="figure">
                    <img class="figure-img img-fluid img-fluid mx-auto d-block" :src="imgModal">
                  </figure>
                </div>
              </div>
            </div>
          </div>
      </div>
    </section>
    <!--End Orchid Section-->
</template>

<script>
import { Carousel, Slide, Navigation } from 'vue3-carousel'
import AImg1 from '@/assets/images/product/anggrek/01.jpg'
import AImg2 from '@/assets/images/product/anggrek/02.jpg'
import AImg3 from '@/assets/images/product/anggrek/03.jpg'
import AImg4 from '@/assets/images/product/anggrek/04.jpg'
import AImg5 from '@/assets/images/product/anggrek/05.jpg'
import AImg6 from '@/assets/images/product/anggrek/06.jpg'
import AImg7 from '@/assets/images/product/anggrek/07.jpg'
import AImg8 from '@/assets/images/product/anggrek/08.jpg'
import AImg9 from '@/assets/images/product/anggrek/09.jpg'
import AImg10 from '@/assets/images/product/anggrek/10.jpg'
import AImg11 from '@/assets/images/product/anggrek/11.jpg'
import AImg12 from '@/assets/images/product/anggrek/12.jpg'
import AImg13 from '@/assets/images/product/anggrek/13.jpg'
import AImg14 from '@/assets/images/product/anggrek/14.jpg'
import AImg15 from '@/assets/images/product/anggrek/15.jpg'
import AImg16 from '@/assets/images/product/anggrek/16.jpg'
import AImg17 from '@/assets/images/product/anggrek/17.jpg'
import AImg18 from '@/assets/images/product/anggrek/18.jpg'
import AImg19 from '@/assets/images/product/anggrek/19.jpg'
import AImg20 from '@/assets/images/product/anggrek/20.jpg'
import AImg21 from '@/assets/images/product/anggrek/21.jpg'
import AImg22 from '@/assets/images/product/anggrek/22.jpg'
import AImg23 from '@/assets/images/product/anggrek/23.jpg'
import AImg24 from '@/assets/images/product/anggrek/24.jpg'
import AImg25 from '@/assets/images/product/anggrek/25.jpg'
import AImg26 from '@/assets/images/product/anggrek/26.jpg'
import AImg27 from '@/assets/images/product/anggrek/27.jpg'
import AImg28 from '@/assets/images/product/anggrek/28.jpg'
import AImg29 from '@/assets/images/product/anggrek/29.jpg'
import AImg30 from '@/assets/images/product/anggrek/30.jpg'
import AImg31 from '@/assets/images/product/anggrek/31.jpg'
import AImg32 from '@/assets/images/product/anggrek/32.jpg'
import AImg33 from '@/assets/images/product/anggrek/33.jpg'
import 'vue3-carousel/dist/carousel.css'

export default {
  name: 'ComponentsOrchid',
  components: {
    Carousel,
    Slide,
    Navigation,
  },
  data () {
    return {
      showModal: 'd-none',
      imgModal: '',
      slider: [
        AImg1, 
        AImg2, 
        AImg3, 
        AImg4, 
        AImg5, 
        AImg6, 
        AImg7, 
        AImg8, 
        AImg9, 
        AImg10,
        AImg11,
        AImg12,
        AImg13,
        AImg14,
        AImg15,
        AImg16,
        AImg17,
        AImg18,
        AImg19,
        AImg20,
        AImg21,
        AImg22,
        AImg23,
        AImg24,
        AImg25,
        AImg26,
        AImg27,
        AImg28,
        AImg29,
        AImg30,
        AImg31,
        AImg32,
        AImg33,
      ]
    }
  },
  methods: {
    modalshow(val) {
      this.imgModal = val
      this.showModal = 'd-block'
    },
    modalclose(val) {
      this.showModal = val
    },
  },
}
</script>