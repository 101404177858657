<template>
  <div>
    <!-- Product -->
      <section class="news-section">
          <div class="auto-container">
              <div class="row">
                  <!-- News Block -->
                  <div class="news-block col-lg-4 col-md-6 col-sm-12">
                      <div class="inner-box">
                          <div class="image-box">
                              <figure class="image"><img src="@/assets/images/product/01.jpg" alt=""></figure>
                          </div>
                          <div class="caption-box">
                              <h3>Moth Orchid</h3>
                              <ul class="info">
                                  <li>ORCHID</li>
                              </ul>
                          </div>
                      </div>
                  </div>

                  <!-- News Block -->
                  <div class="news-block col-lg-4 col-md-6 col-sm-12">
                      <div class="inner-box">
                          <div class="image-box">
                              <figure class="image"><img src="@/assets/images/product/02.jpg" alt=""></figure>
                          </div>
                          <div class="caption-box">
                              <h3>Expression of Appreciation</h3>
                              <ul class="info">
                                  <li>STEEKWERK</li>
                              </ul>
                          </div>
                      </div>
                  </div>

                  <!-- News Block -->
                  <div class="news-block col-lg-4 col-md-6 col-sm-12">
                      <div class="inner-box">
                          <div class="image-box">
                              <figure class="image"><img src="@/assets/images/product/03.jpg" alt=""></figure>
                          </div>
                          <div class="caption-box">
                              <h3>Expression of Saddness</h3>
                              <ul class="info">
                                  <li>STANDING FLOWER</li>
                              </ul>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </section>
    <!--End Product -->
  </div>
</template>

<script>
export default {
  name: 'ComponentsProduct',
}
</script>
