<template>
  <!--Scroll to top-->
  <div class="scroll-to-top scroll-to-target" data-target="html" :class="isTop" @click="scrollToTop()">
    <span class="fa fa-arrow-circle-o-up"></span>
  </div>
</template>

<script>
export default {
  name: 'ComponentsScrollTop',
  data () {
    return {
      isTop: 'd-none',
    }
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
  },
  mounted(){
    window.addEventListener('scroll', () => {
      let scrollPos = window.scrollY
      if(scrollPos >= 100){
          this.isTop = 'd-block'
      } else {
          this.isTop = 'd-none'
      }
    })
  }
}
</script>