<template>
  <div>
    <!--Best Seller Section-->
    <section class="testimonial-section">
      <div class="outer-container clearfix">
          <!-- Title Column -->
          <div class="title-column clearfix">
              <div class="inner-column">
                  <div class="sec-title">
                      <span class="float-text">Product</span>
                      <h2>Best Seller</h2>
                  </div>
                  <div class="text">There are many ways to express feelings at any given moment. One way is to give flowers as a messenger. We will help you get your message across in the moment by providing high quality flowers.</div>
              </div>
          </div>

          <!-- Testimonial Column -->
          <div class="testimonial-column clearfix">
              <div class="inner-column">
                  <div class="testimonial-carousel owl-theme">
                    <div class="col-sm-12">
                      <Carousel :itemsToShow="3.95" :wrapAround="true" :transition="500">
                        <Slide v-for="best in bestSeller" :key="best">
                          <div class="carousel__item">
                            <img :src="best">
                          </div>
                        </Slide>
                        <template #addons>
                          <Pagination />
                        </template>
                      </Carousel>
                    </div>
                  </div>
              </div>
          </div>
      </div>
    </section>
    <!--End Best Seller Section-->
  </div>
</template>

<style>
  .testimonial-section {
    padding-bottom: 100px;
  }
  .testimonial-column {
    background-image: url('@/assets/images/background/4.jpg');
  }
  .carousel__slide {
    padding: 5px;
  }

  .carousel__viewport {
    perspective: 2000px;
  }

  .carousel__track {
    transform-style: preserve-3d;
  }

  .carousel__slide--sliding {
    transition: 0.5s;
  }

  .carousel__slide {
    opacity: 0.9;
    transform: rotateY(-20deg) scale(0.9);
  }

  .carousel__slide--active ~ .carousel__slide {
    transform: rotateY(20deg) scale(0.9);
  }

  .carousel__slide--prev {
    opacity: 1;
    transform: rotateY(-10deg) scale(0.95);
  }

  .carousel__slide--next {
    opacity: 1;
    transform: rotateY(10deg) scale(0.95);
  }

  .carousel__slide--active {
    opacity: 1;
    transform: rotateY(0) scale(1.1);
  }
</style>

<script>
import { Carousel, Pagination, Slide } from 'vue3-carousel'
import Img1 from '@/assets/images/best/01.jpg'
import Img2 from '@/assets/images/best/02.jpg'
import Img3 from '@/assets/images/best/03.jpg'
import Img4 from '@/assets/images/best/04.jpg'
import Img5 from '@/assets/images/best/05.jpg'
import Img6 from '@/assets/images/best/06.jpg'

import 'vue3-carousel/dist/carousel.css'

export default {
  name: 'ComponentsBestSeller',
  components: {
    Carousel,
    Slide,
    Pagination,
  },
  data () {
    return {
      bestSeller: [
        Img1,
        Img2,
        Img3,
        Img4,
        Img5,
        Img6,
      ]
    }
  },
}
</script>
