import { createWebHistory, createRouter } from "vue-router";
import Home from "@/views/Home.vue"
import Product from "@/views/Product.vue"
import About from "@/views/About.vue"
import Contact from "@/views/Contact.vue"

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/product",
    name: "Product",
    component: Product,
  },
  {
    path: "/about",
    name: "About",
    component: About,
  },
  {
    path: "/contact",
    name: "Contact",
    component: Contact,
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

export default router;